import React from 'react';
import { QuestionMarkCircleIcon } from '@heroicons/react/20/solid';

import { Button } from '@nearst/ui';
import { showIntercomArticle } from '@services/Intercom/utils';

import styles from './HelpButton.module.scss';

const HelpButton = ({ href, children }) => {
	// extract the ID from the Intercom article URL
	const intercomArticleId = href.match(/\d{5,}/)?.[0];

	return (
		<Button
			as="a"
			onClick={(event) => {
				if (!window.Intercom || !intercomArticleId) {
					return;
				}
				event.preventDefault();
				showIntercomArticle(intercomArticleId);
			}}
			link
			href={href}
			target="_blank"
			rel="noreferrer"
			className={styles.help}
		>
			<QuestionMarkCircleIcon className={styles.helpIcon} />
			{children}
		</Button>
	);
};

export default HelpButton;
