import React from 'react';
import { format, startOfWeek, endOfWeek } from 'date-fns';

import ReportDownloadCard from '../../../components/ReportDownloadCard';
import { getMultipleInsights, getDailySwisInsights } from '@services/insights';

const FreeLocalListingsPerformance = () => {
	const getData = async ({ shops, ids, startDate, endDate }) => {
		const fromStartOfWeekStr = format(startOfWeek(startDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');
		const toEndOfWeekStr = format(endOfWeek(endDate, { weekStartsOn: 1 }), 'yyyy-MM-dd');

		const queries = [
			{
				id: ids,
				table: 'local_listings',
				columns: ['impressions', 'clicks', 'shop_id', 'date'],
				from: format(startDate, 'yyyy-MM-dd'),
				to: format(endDate, 'yyyy-MM-dd')
			},
			{
				id: ids,
				table: 'local_listings_weekly',
				columns: ['start_date', 'end_date', 'impressions', 'shop_id'],
				filter: [
					['start_date', '>=', fromStartOfWeekStr],
					['end_date', '<=', toEndOfWeekStr]
				],
				sort: [{ column: 'start_date', order: 'desc' }]
			}
		];

		return getMultipleInsights(queries)
			.then(([swisDaily, swisWeekly]) =>
				shops.map((shop) => {
					const swisDailyCorrected = getDailySwisInsights(startDate, endDate, swisDaily, swisWeekly, [shop.id]);
					const locationName = shop.name;
					const websiteViews = swisDailyCorrected.reduce((acc, cur) => (acc += cur.impressions), 0);
					const websiteClicks = swisDaily.filter((item) => item.shop_id === shop.id).reduce((acc, cur) => (acc += cur.clicks), 0);
					const ctr = websiteClicks && websiteViews ? ((websiteClicks / websiteViews) * 100).toFixed(2) : 0;
					return { locationName, websiteViews, websiteClicks, ctr };
				})
			)
			.then((response) =>
				response
					.sort((a, b) => a.locationName.localeCompare(b.locationName))
					.map((row) => {
						return {
							'Location name': row.locationName,
							Views: row.websiteViews,
							'Product clicks': row.websiteClicks,
							CTR: `${row.ctr}%`
						};
					})
			);
	};

	return (
		<ReportDownloadCard
			getData={getData}
			title="Local Listings performance"
			description="This includes views, product views and & clicks across Google surfaces (Google See What’s in Store, Images, Shopping, Lens, Maps)."
		/>
	);
};

export default FreeLocalListingsPerformance;
