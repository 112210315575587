import React from 'react';
import { Link, Navigate } from 'react-router-dom';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

import { Page, Stack } from '@nearst/ui';
import { useShop } from '@services/ShopProvider';
import useChannelInfo from '../useChannelInfo';
import ChannelHeader from '../../components/ChannelHeader';
import FeedQuality from '@components/Feed/FeedQuality';
import FeedDiagnostics from '@components/Feed/FeedDiagnostics';

import styles from './ChannelIssues.module.scss';

const ChannelIssues = () => {
	const { shop } = useShop();
	const { channelId, channelDefinition, status } = useChannelInfo();

	if (!channelDefinition) {
		return <Navigate to={`/channels/${shop.id}`} />;
	}

	return (
		<Stack space="2rem">
			<ChannelHeader channelId={channelId} status={status} />
			<Page.Section className={styles.section}>
				{status === 'pending' && (
					<div className={styles.banner}>
						<Link to={`/channels/${shop.id}/settings/${channelId}`}>
							Complete your setup
							<ChevronRightIcon height="1.25rem" />
						</Link>
					</div>
				)}
				<FeedQuality channelId={channelDefinition.issuesChannelId} />
				<FeedDiagnostics channelId={channelDefinition.issuesChannelId} />
			</Page.Section>
		</Stack>
	);
};

export default ChannelIssues;
