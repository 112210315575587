import React from 'react';

import { HomeIcon } from '@heroicons/react/20/solid';

import { useShop } from '@services/ShopProvider';
import Sidebar from '@components/Sidebar';
import OnboardingAccessControl from '@components/OnboardingAccessControl';
import { Protect } from '@clerk/clerk-react';

const Insights = () => {
	const { shop, data: shops } = useShop();
	const shopSelected = !!shop?.id;

	const onFreemium = shopSelected ? shop.plan === 'freemium' : shops.every((shop) => shop.plan === 'freemium');
	const swisEnabled = shopSelected ? !!shop.swisUrl : shops.some((shop) => !!shop.swisUrl);
	const liaAvailable = shopSelected ? shop.availableChannels?.['local-ads'] : shops.some((shop) => shop.availableChannels?.['local-ads']);

	return (
		<Sidebar.Menu>
			<Sidebar.Item to="/home" activePaths={['/home', '/onboarding', '/insights']}>
				<HomeIcon />
				Dashboard
			</Sidebar.Item>
			<OnboardingAccessControl allowedState={'shop.google.pos-link-ok'}>
				{swisEnabled && (
					<Protect permission="org:insights:read">
						<Sidebar.SubMenu prefix="/insights">
							<Sidebar.SubItem end to="/insights">
								Overview
							</Sidebar.SubItem>
							<Sidebar.SubItem to="/insights/local-listings">Local Listings</Sidebar.SubItem>
							{!onFreemium && liaAvailable && <Sidebar.SubItem to="/insights/local-ads">Local Ads</Sidebar.SubItem>}
							{!shopSelected && <Sidebar.SubItem to="/insights/business-profile">Google Business Profile</Sidebar.SubItem>}
						</Sidebar.SubMenu>
					</Protect>
				)}
			</OnboardingAccessControl>
		</Sidebar.Menu>
	);
};

export default Insights;
