import React from 'react';
import { Protect } from '@clerk/clerk-react';

import { Page, Stack } from '@nearst/ui';
import DatePicker from '../../components/DatePicker';
import { InsightsDateProvider } from '@services/InsightsDateProvider';
import LocalAdsPerformance from './LocalAdsPerformance';
import FreeLocalListingsPerformance from './FreeLocalListingsPerformance';
import ClicksToSalesReport from './ClicksToSalesReport';
import PerformanceOverview from './PerformanceOverview';
import AccessDenied from '../errors/AccessDenied';
import { useShop } from '@services/ShopProvider';

const Reports = () => {
	const { data: shops } = useShop();
	const locationsWithLiaAvailable = shops.filter((shop) => shop.availableChannels?.['local-ads']);

	return (
		<Protect permission="org:reports:read" fallback={<AccessDenied />}>
			<Page.Header>
				<h1>Export data</h1>
				<p>You can download analytics data to track performance over a specific period of time.</p>
				<DatePicker />
			</Page.Header>
			<Page.Section>
				<Stack>
					{locationsWithLiaAvailable.length ? <LocalAdsPerformance /> : null}
					<FreeLocalListingsPerformance />
					<ClicksToSalesReport />
					<PerformanceOverview />
				</Stack>
			</Page.Section>
		</Protect>
	);
};

export const ReportsLayout = () => (
	<InsightsDateProvider>
		<Reports />
	</InsightsDateProvider>
);
