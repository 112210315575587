import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Card, CheckboxInput, Icon, Page } from '@nearst/ui';

import styles from './LocalCheckout.module.scss';
import GetHelpBlock from '../../components/GetHelpBlock';
import ReservationEmailForm from '../../components/ReservationEmailForm';
import { TelephoneInput } from './PhoneInput';
import ChannelHeader from '../../components/ChannelHeader';
import EmailConfiguration from '../../components/EmailConfiguration';
import DisableCheckoutModal from './DisableCheckoutModal';
import StatusContainer from './StatusContainer';

import { useShop } from '@services/ShopProvider';
import { updateChannel, useChannel } from '@services/channels';
import { createAccount } from '@services/checkout';
import { Protect } from '@clerk/clerk-react';

const CHANNEL_ID = 'local-checkout';

const LocalCheckout = () => {
	const { shop } = useShop();
	const location = useLocation();
	const { data: channelData } = useChannel(CHANNEL_ID, shop.id);

	const [showDialog, setShowDialog] = useState(false);
	const [loading, setLoading] = useState(false);
	const [smsEnabled, setSmsEnabled] = useState(channelData?.config?.smsEnabled);

	const checkoutEnabled = channelData?.status === 'enabled';

	const defaultEmailList = channelData?.config?.notificationEmails || [];
	const defaultPhoneNumbers = channelData?.config?.notificationPhoneNumbers || [];

	// request new account link and redirect the user
	const connect = useCallback(async () => {
		setLoading(true);
		const data = await createAccount(shop.id);
		if (data.link) window.location = data.link;
		setLoading(false);
	}, [shop.id]);

	useEffect(() => {
		// this is the "refresh_url" (provided when creating an AccountLink)
		// this should trigger a method on the server to create a new AccountLink
		if (location.search === '?redirect=1&reason=stripe_refresh') {
			connect();
		}
	}, [connect, location.search, shop.id]);

	return (
		<>
			<ChannelHeader channelId={CHANNEL_ID} status={checkoutEnabled} />
			<Page.Section>
				<Card title="Connection status">
					<StatusContainer shopId={shop.id} status={channelData?.status} handleStripeCallback={connect} loading={loading} />
				</Card>

				{checkoutEnabled && (
					<Protect permission="org:channels:manage">
						{' '}
						<EmailConfiguration shop={shop} channel={CHANNEL_ID} />
					</Protect>
				)}

				{checkoutEnabled && (
					<Card title="Notifications">
						<Protect
							permission="org:channels:manage"
							fallback={
								<div className={styles.disabledState}>
									<Icon>lock</Icon>
									<p>You don't have permission to update local checkout notifications</p>
								</div>
							}
						>
							<div className={styles.channelNotificationInput}>
								<ReservationEmailForm notificationEmails={defaultEmailList} />
							</div>
							<div className={styles.channelNotificationInput}>
								<>
									<label className={styles.telephoneInputLabel}>Notification telephone number</label>
									<TelephoneInput
										country={shop.country.toLowerCase()}
										name="telephone"
										type="text"
										value={defaultPhoneNumbers.toString()}
										onBlur={async (e) => {
											await updateChannel(shop.id, 'product-reservations', {
												notificationPhoneNumbers: [e.target.value] || []
											});
											await updateChannel(shop.id, CHANNEL_ID, {
												notificationPhoneNumbers: [e.target.value] || []
											});
										}}
									/>
								</>
							</div>
							<div className={styles.channelToggled}>
								<CheckboxInput
									label="Send text messages for new orders"
									checked={smsEnabled}
									onClick={async (e) => {
										setSmsEnabled(e.target.checked);
										await updateChannel(shop.id, 'product-reservations', { smsEnabled: e.target.checked });
										await updateChannel(shop.id, CHANNEL_ID, { smsEnabled: e.target.checked });
									}}
								/>
							</div>
						</Protect>
					</Card>
				)}

				<Card title="Get help">
					<GetHelpBlock
						information={[
							'All you need to do to enable checkout is create your Stripe account and we’ll take care of the rest.',
							'Still have questions? You can use the chat bubble in the corner to speak with a member of our Customer Success team.'
						]}
						linksTitle="Check out our support centre:"
						links={[
							{
								text: 'How does Local Checkout work?',
								href: 'https://intercom.help/nearst/en/articles/7056348-how-does-local-checkout-work'
							},
							{
								text: 'How does Local Checkout affect my stock levels?',
								href: 'https://intercom.help/nearst/en/articles/7056358-how-does-local-checkout-affect-my-stock-levels'
							},
							{
								text: 'How much does Local Checkout cost and are there any additional fees?',
								href: 'https://intercom.help/nearst/en/articles/7056351-how-much-does-local-checkout-cost-and-are-there-any-additional-fees'
							},
							{
								text: 'View all FAQs',
								href: 'https://intercom.help/nearst/en/collections/3891918-local-checkout'
							}
						]}
					/>
				</Card>
				{checkoutEnabled ? (
					<Protect permission="org:channels:manage">
						<Card>
							<div className={styles.channelDisableCheckout}>
								<Button onClick={() => setShowDialog(true)}>Disable checkout</Button>
							</div>
						</Card>
					</Protect>
				) : null}
			</Page.Section>
			<DisableCheckoutModal shop={shop} showDialog={showDialog} setShowDialog={setShowDialog} />
		</>
	);
};

export default LocalCheckout;
