import React, { useMemo } from 'react';
import useSWR from 'swr';

import { Card } from '@nearst/ui';
import { getFeedQualityStats } from '@services/stock';
import { useShop } from '@services/ShopProvider';

import HorizontalBar from '@components/Graphs/HorizontalBar';

import styles from './FeedQuality.module.scss';

export const FeedQualityBar = ({ data }) => {
	const segments = useMemo(() => {
		const values = [
			{
				title: 'active products',
				description: 'In-stock items with no issues',
				count: data.activeItems,
				style: styles.activeItemsSegment
			},
			{
				title: 'products with errors',
				description: 'In-stock items not visible due to issues',
				count: data.totalItems - data.activeItems - data.inactiveItems,
				style: styles.errorItemsSegment
			},
			{
				title: 'inactive products',
				description: 'Out-of-stock or hidden items',
				count: data.inactiveItems
			}
		];

		const total = data.totalItems;
		for (const segment of values) {
			segment.percentage = Math.round((segment.count / total) * 100);
		}

		return values;
	}, [data]);

	return <HorizontalBar segments={segments} />;
};

const FeedQuality = ({ channelId = 'nearst' }) => {
	const { shop } = useShop();
	const key = channelId ? `${shop.id}-${channelId}-feed-quality-stats` : `${shop.id}-feed-quality-stats`;

	const { data, isLoading } = useSWR(key, () => getFeedQualityStats(shop.id, channelId));

	return <Card title="Feed quality report">{isLoading ? <HorizontalBar.Loading /> : <FeedQualityBar data={data} />}</Card>;
};

export default FeedQuality;
