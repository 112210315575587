import React from 'react';
import { format } from 'date-fns';
import { getMultipleInsights } from '@services/insights';
import ReportDownloadCard from '../../../components/ReportDownloadCard';

const ClicksToSalesReport = () => {
	const getData = async ({ ids, shops, startDate, endDate }) => {
		const from = format(startDate, 'yyyy-MM-dd');
		const to = format(endDate, 'yyyy-MM-dd');

		const query = {
			id: ids,
			table: 'clicks_to_sales',
			sort: [
				{ column: 'sales_date', order: 'desc' },
				{ column: 'sales_from_clicks', order: 'desc' },
				{ column: 'attributed_clicks', order: 'desc' }
			],
			from,
			to
		};

		const response = await getMultipleInsights([query]);

		return response.map((row) => {
			const shop = shops.find((s) => s.id === row.shop_id);

			return {
				Location: shop.name,
				Barcode: row.barcode,
				'Product name': row.product_name,
				'Sales date': row.sales_date,
				Price: (+row.price).toLocaleString(`en`, { style: 'currency', currency: shop.currency }),
				'Attributed clicks': row.attributed_clicks,
				'Product clicks to sales': row.sales_from_clicks
			};
		});
	};

	return (
		<ReportDownloadCard
			getData={getData}
			accessLevel="plan"
			title="Clicks to sales"
			description="This includes any products that received clicks on your ads and are matched to suspected sales in the next 24 hours."
		/>
	);
};

export default ClicksToSalesReport;
