import React from 'react';
import { Protect } from '@clerk/clerk-react';
import { Navigate, Outlet } from 'react-router-dom';

import { InsightsDateProvider } from '@services/InsightsDateProvider';

import LocalAdsChecker from '@components/LocalAdsChecker';
import AccessDenied from '../errors/AccessDenied';

import BusinessProfile from './BusinessProfile';
import IndustryInsights from './IndustryInsights';
import LocalAds from './LocalAds';
import SWIS from './SWIS';
import ClicksToSales from './ClicksToSales';
import InsightsDashboard from './InsightsDashboard';
import LocalListingsChain from './LocalListingsChain';
import LocalAdsChain from './LocalAdsChain';

export const InsightsLayout = () => {
	return (
		<InsightsDateProvider>
			<Protect permission="org:insights:read" fallback={<AccessDenied />}>
				<Outlet />
			</Protect>
		</InsightsDateProvider>
	);
};

export const insightsRoutes = [
	// chain
	{ path: 'local-listings', element: <LocalListingsChain /> },
	{ path: 'local-ads', element: <LocalAdsChain /> },
	// both
	{
		path: ':shopId?',
		children: [
			{ path: 'business-profile', element: <BusinessProfile /> },
			{ index: true, element: <InsightsDashboard /> }
		]
	},
	// shop only
	{
		path: ':shopId',
		children: [
			{ path: 'industry', element: <IndustryInsights /> },
			{ path: 'google-swis', element: <Navigate to={'local-listings'} /> },
			{ path: 'local-listings', element: <SWIS /> },
			{
				path: 'local-ads',
				element: (
					<LocalAdsChecker>
						<LocalAds />
					</LocalAdsChecker>
				)
			},
			{
				path: 'clicks-to-sales',
				element: (
					<LocalAdsChecker>
						<ClicksToSales />
					</LocalAdsChecker>
				)
			}
		]
	}
];
