import React from 'react';
import { Card } from '@nearst/ui';
import { ReactComponent as GoogleAdsCertification } from '../../../assets/icons/certificate.svg';
import styles from './LocalAdsManager.module.scss';

const LocalAdsManager = ({ contact }) => {
	const avatarUrl = `https://near.st/production/avatar/${contact.email}`;
	return (
		<Card>
			<div className={styles.card}>
				<div className={styles.left}>
					<img src={avatarUrl} alt="Local Ads Manager" className={styles.image} />
					<div className={styles.leftText}>
						<p className={styles.topText}>Your Local Inventory Ads Expert</p>
						<p className={styles.boldText}>{contact.name}</p>
					</div>
				</div>
				<div className={styles.right}>
					<div className={styles.rightText}>
						<p className={`${styles.topText} ${styles.boldText} ${styles.certifiedText}`}>Google Ads Certified</p>
					</div>
					<a href="https://partnersdirectory.withgoogle.com/partners/3113577254" rel="noreferrer" target="_blank">
						<GoogleAdsCertification width="80" alt="Google Ads Certificate" className={styles.certificate} />
					</a>
				</div>
			</div>
		</Card>
	);
};

export default LocalAdsManager;
