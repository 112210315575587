import React, { useState } from 'react';
import mixpanel from 'mixpanel-browser';
import { Loader, ActualButton, Icon, Modal, Cluster } from '@nearst/ui';

import styles from './DisableReservationsModal.module.scss';

const DisableReservationsModal = ({ showDialog, setShowDialog, shop, handleDisableCallback }) => {
	const close = () => setShowDialog(false);
	const [loading, setLoading] = useState(false);

	const confirm = async () => {
		setLoading(true);
		mixpanel.track('Reservations disabled', { distinct_id: shop.id });
		await handleDisableCallback();
		setLoading(false);
	};

	return (
		<Modal className={styles.reservationsModal} isOpen={showDialog} onDismiss={close} aria-label="reservations-modal">
			<div className={styles.reservationsModalTitleContainer}>
				<h2 className={styles.reservationsModalTitleText}>Disable Product Reservations</h2>
				<button className={styles.reservationsModalExitButton} onClick={close}>
					<Icon>clear</Icon>
				</button>
			</div>
			<div className={styles.reservationsModalContainer}>
				<div>
					<p>If you disable product reservations and want to re-enable at a future date, this may take up to 7 days.</p>
					<p>Are you sure you want to disable it?</p>

					<Cluster className={styles.reservationsModalActionButton}>
						<ActualButton onClick={confirm} warning>
							Disable reservations
						</ActualButton>
						{loading && <Loader />}
					</Cluster>
				</div>
			</div>
		</Modal>
	);
};

export default DisableReservationsModal;
