import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Cog6ToothIcon } from '@heroicons/react/20/solid';

import { Cluster, Page, Pill, UItils } from '@nearst/ui';

import styles from './ChannelPageHeader.module.scss';

import { useShop } from '@services/ShopProvider';
import { useChannelDefinitions } from '@services/channels';
import { useTemplateRouting } from '../../TemplatePages/TemplateRouter';

const statusLookup = {
	pending: { colour: 'grey', title: 'In setup' },
	enabled: { colour: 'green', title: 'Enabled' },
	comingSoon: { colour: 'blue', title: 'Coming soon' }
};

const ChannelHeader = ({ channelId, status }) => {
	const { shop } = useShop();
	const location = useLocation();
	const homeRoute = useTemplateRouting(channelId);
	const subsection = location.pathname.replace(`/channels/${shop.id}/`, '').split('/')[0];
	const { data: channelDefinitions } = useChannelDefinitions();
	const channelDefinition = channelDefinitions[channelId];
	const statusOption = statusLookup[channelDefinition.comingSoon ? 'comingSoon' : status];
	const showSettingsLink = (status === 'enabled' || status === 'pending') && channelDefinition.manageUrl && subsection !== 'settings';

	return (
		<Page.Header>
			<div>
				<Cluster space="0.25rem">
					<Link to={`/channels/${shop.id}`}>
						<Page.BackButton>Channels</Page.BackButton>
					</Link>

					<Link replace={homeRoute === location.pathname} to={homeRoute}>
						<Page.BackButton>{channelDefinition.title}</Page.BackButton>
					</Link>

					{subsection && (
						<div>
							<Page.BackButton className={UItils.classnames(styles.disabled, styles.last)}>{subsection}</Page.BackButton>
						</div>
					)}
				</Cluster>
				<div className={styles.title}>
					<div className={styles.logo}>
						<img src={channelDefinition.iconUrl} alt={`Logo for ${channelDefinition.title}`} />
					</div>
					<div>
						<span className={styles.titleContainer}>
							<h1>{channelDefinition.title}</h1>
							{statusOption && <Pill colour={statusOption.colour}>{statusOption.title}</Pill>}
						</span>
						<p className={styles.description}>{channelDefinition.description}</p>
					</div>
					{showSettingsLink && (
						<Link
							data-cy="channel-settings-link"
							className={styles.settingsIcon}
							to={`/channels/${shop.id}/settings/${channelId}`}
						>
							<Cog6ToothIcon height="20px" />
						</Link>
					)}
				</div>
			</div>
		</Page.Header>
	);
};

export default ChannelHeader;
