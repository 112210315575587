import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import Sidebar from '@components/Sidebar';
import AccountManagement from './AccountManagement';
import Insights from './Insights';
import Orders from './Orders';
import Channels from './Channels';
import Inventory from './Inventory';
import LocalAds from './LocalAds';
import Reports from './Reports';

import Locations from './Locations';
import LocationSelector from './LocationSelector';
import MobileNavigator from './MobileNavigator';

const MyShopNavigation = () => {
	const { pathname, key } = useLocation();
	const [mobileExpanded, setMobileExpanded] = useState(false);

	useEffect(() => {
		setMobileExpanded(false);
	}, [key]);

	return (
		<>
			<MobileNavigator onToggle={() => setMobileExpanded(!mobileExpanded)} mobileExpanded={mobileExpanded} />
			<Sidebar mobileExpanded={mobileExpanded}>
				{/* TODO: check how upgrade path looks */}
				{!pathname.includes('upgrade') && (
					<>
						<LocationSelector />
						<Sidebar.Group>
							<Insights />
							<Locations />
							<Orders />
							<Inventory />
							<Channels />
							<LocalAds />
							<Reports />
						</Sidebar.Group>
					</>
				)}
				<Sidebar.Group>
					<AccountManagement />
				</Sidebar.Group>
			</Sidebar>
		</>
	);
};

export default MyShopNavigation;
