import React from 'react';
import { Box } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import PlaceholderContainer from './PlaceholderContainer';
import { ReactComponent as PlaceholderIllustration } from '../../assets/illustrations/excluded-barcodes.svg';

const EmptyResultPlaceHolder = () => (
	<PlaceholderContainer>
		<PlaceholderIllustration />
		<div>No results found</div>
	</PlaceholderContainer>
);

const TablePlaceholder = ({ rows = 6, rowWidth = '100%', rowHeight = 80 }) => (
	<Box>
		{Array.from({ length: rows }, (_, i) => i + 1).map((row) => {
			return <Skeleton key={`row-${row}`} width={rowWidth} height={rowHeight} />;
		})}
	</Box>
);

export { TablePlaceholder, EmptyResultPlaceHolder };
